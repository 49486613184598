<template>
  <div class="content">
    <div class="title title--big title--theme title--indent">Список пользователей</div>
    <app-table-head
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
      @update-limiter="onUpdateLimiter"
    />
    <app-cells position="start">
      <v-select
        @search="onFilterSearch"
        noDrop
        label="id"
        placeholder="Поиск по ФИО или ID"
        class="select select--minw"
      >
      </v-select>
    </app-cells>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      @row-click="onRowClick"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="crop_url" slot-scope="props" class="table-default__logo">
        <img v-if="props.row.crop_url" :src="props.row.crop_url" alt="">
        <span v-else>-</span>
      </div>
      <div slot="last_name" slot-scope="props" class="table-default__left">
        {{ props.row.last_name }} {{ props.row.first_name }} {{ props.row.patronymic }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'UsersList',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'crop_url', 'last_name', 'dob', 'phone', 'email'],
        options: {
          headings: {
            id: 'ID',
            crop_url: 'Фото',
            last_name: 'ФИО',
            dob: 'Дата рождения',
            phone: 'Телефон',
            email: 'E-mail',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
    }
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    fetchItems(page = this.pagination.page, page_size = 20, search = '') {
      this.$store.dispatch('admin/users/GET_LIST', { page, page_size, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onRowClick(row) {
      this.$router.push({ name: 'admin-user-detail', params: { id: row.row.id } })
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
  }
}
</script>

<style lang="sass" scoped>

</style>
